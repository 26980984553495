import { Button } from "@mantine/core"
import axios from "axios"

interface SendButtonInterface{
    contacts:string[]
    context:string
}

export const SendButton = (props:SendButtonInterface) => {
    return(
        <Button onClick={()=>axios.post("https://centarnit.deta.dev/notify/", props).then((r)=>alert("Sent!"))}>
                Send Message
            </Button>
    )
}