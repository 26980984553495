import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Messages } from "./Messages/Messages";

export const AppRoutes = () => {
    return (
        <BrowserRouter>
            <div>
                <Routes>
                    { <Route path="/" element={<Messages/>}/>}
                </Routes>
            </div>
        </BrowserRouter>
    );
};
