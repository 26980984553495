import { useState } from 'react';
import { Textarea } from '@mantine/core';
import { SendButton } from './MessagesComponents/SendButton';

export const Messages = () => {
    const [contacts, setContacts] = useState('');
    return (
        <>
        <div style={{"display":"flex", "justifyContent":"space-around", "width":"100%", padding:"0% 10%"}}>
            <Textarea
            placeholder="Enter contacts for message: "
            autosize
            minRows={10}
            style={{"width":"40%", "marginTop":"5%"}}
            onChange={(event) => setContacts(event.target.value)} />
            
            <Textarea
            placeholder="Enter message: "
            autosize
            minRows={10}
            style={{"width":"40%", "marginTop":"5%"}}
            onChange={(event) => setContacts(event.target.value)} />
        </div>
        <div style={{"display":"flex",justifyContent:"center", width:"100%", marginTop:"5%"}}>
        <SendButton contacts={contacts.replace("\n", ",").split(",")} context="test"/>
        </div>
        </>
    )
}